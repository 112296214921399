@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
:root{
  --red : #ef5455;
  --yellow : #fad744;
  --bluish : #2b3252;
  --lightR : #fef6f6;
}
*{
  
  
}